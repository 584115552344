import React, { Component } from 'react'
import { connect } from 'react-redux'

export class ComparasionChart extends Component {
    render() {
        return (
            <>
                <section className="pricing">
                    <div className="container">
                        <div className="section-title text-center">
                            <h3 className="sub-title wow pixFadeUp">Modernizing Sales Dynamics</h3>
                            <h2 className="title wow pixFadeUp" data-wow-delay="0.3s">
                                Traditional Partners <br></br>vs.<br></br> Tourzone  Visionaries
                            </h2>
                        </div>

                        <div className="row advanced-pricing-table no-gutters wow pixFadeUp" data-wow-delay="0.5s">


                            <div className="col-lg-6">
                                <div className="pricing-table color-two">
                                    <div className="pricing-header pricing-amount">
                                        <h3 className="price-title">Traditional Sales Partners</h3>

                                    </div>


                                    <ul className="price-feture">
                                        <li className="not">Complex Reservations with Paper & Pen</li>
                                        <li className="not">Manual Pricing, Fixed Rates  </li>
                                        <li className="not">Manual Inventory Control   </li>
                                        <li className="not">Limited Online Reach </li>
                                        <li className="not">Paper-Based Inefficiencies </li>

                                        <li className="not">Location-Restricted Access</li>
                                        <li className="not">Limited Offline Audience  </li>
                                        <li className="not">Aspiration to Increase Bookings</li>
                                        <li className="not">Manual Processes and Effort </li>

                                        <li className="not">Limited Means to Enhance Experience</li>
                                        <li className="not">Restricted Customer Reach </li>
                                    </ul>

                                </div>

                            </div>


                            <div className="col-lg-6">
                                <div className="pricing-table color-three" style={{ borderRight: 0 }}>

                                    <div className="pricing-header pricing-amount">
                                        <h3 className="price-title">Tourzone Sales Partners</h3>

                                    </div>


                                    <ul className="price-feture">
                                        <li className="have">Real-Time Booking for More Sales</li>
                                        <li className="have">Smart Pricing for Increased Sales</li>
                                        <li className="have">Automated Inventory Management</li>
                                        <li className="have">Wider Distribution for More Sales</li>
                                        <li className="have">Paperless Efficiency for Sales</li>

                                        <li className="have">Anytime, Anywhere Access for Sales</li>
                                        <li className="have">Broaden Audience with Online Bookings</li>
                                        <li className="have">Boost Bookings and Sales Opportunities</li>
                                        <li className="have">Streamlined Automation for Efficiency</li>

                                        <li className="have">Enhanced Guest Experience and Satisfaction</li>
                                        <li className="have">Expanded Reach to More Customers</li>
                                    </ul>


                                </div>

                            </div>

                        </div>

                    </div>
                </section>

                <section className="call-to-action">

                    <div className="container">
                        <div className="action-content text-center wow pixFadeUp">
                            <h2 className="title">
                                Unlock more leisure time <br></br>for your favorite activities.
                            </h2>

                            <p>
                                Say goodbye to complexity in sales partnerships. <br></br> Welcome the ease of Tourzone Platform. Begin your journey now!


                            </p>

                            <a href="https://partners.tourzone.com" rel="noreferrer" className="pix-btn btn-light">Get Started now</a>
                        </div>

                    </div>




                </section>
            </>
        )
    }
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(ComparasionChart)