import React, { Component } from 'react'
import { connect } from 'react-redux'
import Headers from './Headers'
import Footer from './Footer'

export class Layout extends Component {



    render() {
        return (
            <>
                <Headers dark={this.props.dark} />
                {this.props.children}
                <Footer />
            </>
        )
    }
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(Layout)