import React, { Component, Suspense } from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { connect } from 'react-redux'
import ComparasionChartSolutions from "../../Solutions/Compenent/ComparasionChartSolutions"
import Layout from '../../Layout/Layout';
import Loading from '../../../Helper/Loading';
import ComparasionChart from '../../Partners/Compenent/ComparasionChart';

const Home = React.lazy(() => import('../../Home/Compenent/Home'));
const Blog = React.lazy(() => import('../../Blog/Component/Blog'));
const Company = React.lazy(() => import('../../Company/Compenent/Company'));
const Contact = React.lazy(() => import('../../Contact/Component/Contact'));
const RequestDemo = React.lazy(() => import('../../Contact/Component/RequestDemo'));

const Solutions = React.lazy(() => import('../../Solutions/Compenent/Solutions'));
const TravelAgents = React.lazy(() => import('../../Partners/Compenent/TravelAgents'));
const Airlines = React.lazy(() => import('../../Partners/Compenent/Airlines'));
const Hotels = React.lazy(() => import('../../Partners/Compenent/Hotels'));
const TraverBlogger = React.lazy(() => import('../../Partners/Compenent/TraverBlogger'));
const Dmo = React.lazy(() => import('../../Partners/Compenent/Dmo'));

const Dmc = React.lazy(() => import('../../Partners/Compenent/Dmc'));
const Ota = React.lazy(() => import('../../Partners/Compenent/Ota'));
const Cruises = React.lazy(() => import('../../Partners/Compenent/Cruises'));
const CarRental = React.lazy(() => import('../../Partners/Compenent/CarRental'));
const VisitorCenters = React.lazy(() => import('../../Partners/Compenent/VisitorCenters'));
const Partners = React.lazy(() => import('../../Partners/Compenent/Partners'));
const PartnerSolutions = React.lazy(() => import('../../Solutions/Compenent/PartnerSolutions'));
const Distribution = React.lazy(() => import('../../Solutions/Compenent/Distribution'));

const Industries = React.lazy(() => import('../../Industries/Compenent/Industries'));
const ToursActivities = React.lazy(() => import('../../Industries/Compenent/ToursActivities'));
const Trips = React.lazy(() => import('../../Industries/Compenent/Trips'));
const Attractions = React.lazy(() => import('../../Industries/Compenent/Attractions'));
const Transportation = React.lazy(() => import('../../Industries/Compenent/Transportation'));
const Workshop = React.lazy(() => import('../../Industries/Compenent/Workshop'));
const Events = React.lazy(() => import('../../Industries/Compenent/Events'));
const Charters = React.lazy(() => import('../../Industries/Compenent/Charters'));
const ComparasionChartSupplier = React.lazy(() => import('../../Industries/Compenent/ComparasionChartSupplier'));





export class App extends Component {
    render() {
        return (
            <>
                <BrowserRouter>
                    <Routes>

                        <Route exact path="/" element={
                            <Layout>
                                <Suspense fallback={<Loading />}>
                                    <Home />
                                </Suspense>
                            </Layout>}>
                        </Route>

                        <Route exact path="/Company" element={
                            <Layout dark="yes">
                                <Suspense fallback={<Loading />}>
                                    <Company />
                                </Suspense>
                            </Layout>}>
                        </Route>

                        <Route exact path="/Request-a-demo" element={
                            <Layout dark="yes">
                                <Suspense fallback={<Loading />}>
                                    <RequestDemo/>
                                </Suspense>
                            </Layout>}>
                        </Route>

                        <Route exact path="/Contact" element={
                            <Layout dark="yes">
                                <Suspense fallback={<Loading />}>
                                    <Contact/>
                                </Suspense>
                            </Layout>}>
                        </Route>

                        <Route exact path="/Blog" element={
                            <Layout dark="yes">
                                <Suspense fallback={<Loading />}>
                                    <Blog />
                                </Suspense>
                            </Layout>}>
                        </Route>

                        <Route exact path="/Solutions" element={
                            <Layout dark="yes">
                                <Suspense fallback={<Loading />}>
                                    <Solutions />
                                </Suspense>
                            </Layout>}>
                        </Route>

                        <Route exact path="/Solutions/Partner-solutions" element={
                            <Layout dark="yes">
                                <Suspense fallback={<Loading />}>
                                    <PartnerSolutions/>
                                    <ComparasionChartSolutions/>
                                </Suspense>
                            </Layout>}>
                        </Route>

                        <Route exact path="/Solutions/Distribution" element={
                            <Layout dark="yes">
                                <Suspense fallback={<Loading />}>
                                    <Distribution/>
                                    <ComparasionChartSolutions/>
                                </Suspense>
                            </Layout>}>
                        </Route>

                        <Route exact path="/Industries" element={
                            <Layout dark="yes">
                                <Suspense fallback={<Loading />}>
                                    <Industries />
                                </Suspense>
                            </Layout>}>
                        </Route>
                        <Route exact path="/Industries/Tours-And-Activities" element={
                            <Layout dark="yes">
                                <Suspense fallback={<Loading />}>
                                    <ToursActivities />
                                    <ComparasionChartSupplier />
                                </Suspense>
                            </Layout>}>
                        </Route>
                        <Route exact path="/Industries/Trips" element={
                            <Layout dark="yes">
                                <Suspense fallback={<Loading />}>
                                    <Trips />
                                    <ComparasionChartSupplier />
                                </Suspense>
                            </Layout>}>
                        </Route>

                        <Route exact path="/Industries/Attractions" element={
                            <Layout dark="yes">
                                <Suspense fallback={<Loading />}>
                                    <Attractions />
                                    <ComparasionChartSupplier />
                                </Suspense>
                            </Layout>}>
                        </Route>

                        <Route exact path="/Industries/Transportation" element={
                            <Layout dark="yes">
                                <Suspense fallback={<Loading />}>
                                    <Transportation />
                                    <ComparasionChartSupplier />
                                </Suspense>
                            </Layout>}>
                        </Route>

                        <Route exact path="/Industries/Workshops-and-classes" element={
                            <Layout dark="yes">
                                <Suspense fallback={<Loading />}>
                                    <Workshop />
                                    <ComparasionChartSupplier />
                                </Suspense>
                            </Layout>}>
                        </Route>


                        <Route exact path="/Industries/Event-and-shows" element={
                            <Layout dark="yes">
                                <Suspense fallback={<Loading />}>
                                    <Events />
                                    <ComparasionChartSupplier />
                                </Suspense>
                            </Layout>}>
                        </Route>



                        <Route exact path="/Industries/Charters" element={
                            <Layout dark="yes">
                                <Suspense fallback={<Loading />}>
                                    <Charters />
                                    <ComparasionChartSupplier />
                                </Suspense>
                            </Layout>}>
                        </Route>


                        <Route exact path="/Partners" element={
                            <Layout dark="yes">
                                <Suspense fallback={<Loading />}>
                                   <Partners/>
                                </Suspense>
                            </Layout>}>
                        </Route>

                        <Route exact path="/Partners/Travel-Agencies" element={
                            <Layout dark="yes">
                                <Suspense fallback={<Loading />}>
                                    <TravelAgents />
                                    <ComparasionChart />
                                </Suspense>
                            </Layout>}>
                        </Route>

                        <Route exact path="/Partners/Airlines" element={
                            <Layout dark="yes">
                                <Suspense fallback={<Loading />}>
                                    <Airlines />
                                    <ComparasionChart />
                                </Suspense>
                            </Layout>}>
                        </Route>

                        <Route exact path="/Partners/Hotels" element={
                            <Layout dark="yes">
                                <Suspense fallback={<Loading />}>
                                    <Hotels />
                                    <ComparasionChart />
                                </Suspense>
                            </Layout>}>
                        </Route>

                        <Route exact path="/Partners/Travel-bloggers-affiliate-partners" element={
                            <Layout dark="yes">
                                <Suspense fallback={<Loading />}>
                                    <TraverBlogger />
                                    <ComparasionChart />
                                </Suspense>
                            </Layout>}>
                        </Route>

                        <Route exact path="/Partners/DMO" element={
                            <Layout dark="yes">
                                <Suspense fallback={<Loading />}>
                                    <Dmo />
                                    <ComparasionChart />
                                </Suspense>
                            </Layout>}>
                        </Route>

                        <Route exact path="/Partners/DMC" element={
                            <Layout dark="yes">
                                <Suspense fallback={<Loading />}>
                                    <Dmc />
                                    <ComparasionChart />
                                </Suspense>
                            </Layout>}>
                        </Route>
                        <Route exact path="/Partners/OTA" element={
                            <Layout dark="yes">
                                <Suspense fallback={<Loading />}>
                                    <Ota />
                                    <ComparasionChart />
                                </Suspense>
                            </Layout>}>
                        </Route>

                        <Route exact path="/Partners/Cruises" element={
                            <Layout dark="yes">
                                <Suspense fallback={<Loading />}>
                                    <Cruises />
                                    <ComparasionChart />
                                </Suspense>
                            </Layout>}>
                        </Route>

                        <Route exact path="/Partners/Car-Rental" element={
                            <Layout dark="yes">
                                <Suspense fallback={<Loading />}>
                                    <CarRental />
                                    <ComparasionChart />
                                </Suspense>
                            </Layout>}>
                        </Route>

                        <Route exact path="/Partners/Visitor-Centers" element={
                            <Layout dark="yes">
                                <Suspense fallback={<Loading />}>
                                    <VisitorCenters />
                                    <ComparasionChart />
                                </Suspense>
                            </Layout>}>
                        </Route>


                    </Routes>
                </BrowserRouter>
            </>
        )
    }
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(App)