import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom';
import "vanilla-cookieconsent/dist/cookieconsent.js"
import 'vanilla-cookieconsent/dist/cookieconsent.css'

export class Footer extends Component {




    componentDidMount() {
        const cookieConsent = window.initCookieConsent()
        cookieConsent.run({
            current_lang: 'en',
            autoclear_cookies: true,                   // default: false
            page_scripts: true,                        // default: false

            // mode: 'opt-in'                          // default: 'opt-in'; value: 'opt-in' or 'opt-out'
            // delay: 0,                               // default: 0
            auto_language: false,                     // default: null; could also be 'browser' or 'document'
            // autorun: true,                          // default: true
            // force_consent: false,                   // default: false
            // hide_from_bots: true,                   // default: true
            // remove_cookie_tables: false             // default: false
            // cookie_name: 'cc_cookie',               // default: 'cc_cookie'
            // cookie_expiration: 182,                 // default: 182 (days)
            // cookie_necessary_only_expiration: 182   // default: disabled
            // cookie_domain: location.hostname,       // default: current domain
            // cookie_path: '/',                       // default: root
            // cookie_same_site: 'Lax',                // default: 'Lax'
            // use_rfc_cookie: false,                  // default: false
            // revision: 0,                            // default: 0

            onFirstAction: function (user_preferences, cookie) {
                // callback triggered only once
            },

            onAccept: function (cookie) {
                // ...
            },

            onChange: function (cookie, changed_preferences) {
                // ...
            },

            languages: {
                'en': {
                    consent_modal: {
                        title: 'We use cookies!',
                        description: 'Hi, this website uses essential cookies to ensure its proper operation and tracking cookies to understand how you interact with it. The latter will be set only after consent. <button type="button" data-cc="c-settings" className="cc-link">Let me choose</button>',
                        primary_btn: {
                            text: 'Accept all',
                            role: 'accept_all'              // 'accept_selected' or 'accept_all'
                        },
                        secondary_btn: {
                            text: 'Reject all',
                            role: 'accept_necessary'        // 'settings' or 'accept_necessary'
                        }
                    },
                    settings_modal: {
                        title: 'Cookie preferences',
                        save_settings_btn: 'Save settings',
                        accept_all_btn: 'Accept all',
                        reject_all_btn: 'Reject all',
                        close_btn_label: 'Close',
                        cookie_table_headers: [
                            { col1: 'Name' },
                            { col2: 'Domain' },
                            { col3: 'Expiration' },
                            { col4: 'Description' }
                        ],
                        blocks: [
                            {
                                title: 'Cookie usage ',
                                description: 'I use cookies to ensure the basic functionalities of the website and to enhance your online experience. You can choose for each category to opt-in/out whenever you want. For more details relative to cookies and other sensitive data, please read the full <a href="#" className="cc-link">privacy policy</a>.'
                            }, {
                                title: 'Strictly necessary cookies',
                                description: 'These cookies are essential for the proper functioning of my website. Without these cookies, the website would not work properly',
                                toggle: {
                                    value: 'necessary',
                                    enabled: true,
                                    readonly: true          // cookie categories with readonly=true are all treated as "necessary cookies"
                                }
                            }, {
                                title: 'Performance and Analytics cookies',
                                description: 'These cookies allow the website to remember the choices you have made in the past',
                                toggle: {
                                    value: 'analytics',     // your cookie category
                                    enabled: false,
                                    readonly: false
                                },
                                cookie_table: [             // list of all expected cookies
                                    {
                                        col1: '^_ga',       // match all cookies starting with "_ga"
                                        col2: 'google.com',
                                        col3: '2 years',
                                        col4: 'description ...',
                                        is_regex: true
                                    },
                                    {
                                        col1: '_gid',
                                        col2: 'google.com',
                                        col3: '1 day',
                                        col4: 'description ...',
                                    }
                                ]
                            }, {
                                title: 'Advertisement and Targeting cookies',
                                description: 'These cookies collect information about how you use the website, which pages you visited and which links you clicked on. All of the data is anonymized and cannot be used to identify you',
                                toggle: {
                                    value: 'targeting',
                                    enabled: false,
                                    readonly: false
                                }
                            }, {
                                title: 'More information',
                                description: 'For any queries in relation to our policy on cookies and your choices, please <a className="cc-link" href="#yourcontactpage">contact us</a>.',
                            }
                        ]
                    }
                }
            }
        });
    }



    render() {
        return (
            <>
                <footer id="footer">
                    <div className="container">
                        <div className="footer-inner wow pixFadeUp">
                            <div className="row">
                                <div className="col-lg-3 col-md-6">
                                    <div className="widget footer-widget">
                                        <h3 className="widget-title">Industries</h3>

                                        <ul className="footer-menu">
                                            <li><Link to="/Industries/Tours-And-Activities">Tours & Activities</Link></li>
                                            <li><Link to="/Industries/Trips">Trips</Link></li>
                                            <li><Link to="/Industries/Attractions">Attractions</Link></li>
                                            <li><Link to="/Industries/Transportation">Transportation</Link></li>
                                            <li><Link to="/Industries/Charters">Charters & Rentals</Link></li>
                                            <li><Link to="/Industries/Event-and-shows">Events & Shows</Link></li>
                                            <li><Link to="/Industries/Workshops-and-classes">Workshops & Classes</Link></li>
                                        </ul>
                                    </div>

                                </div>


                                <div className="col-lg-3 col-md-6">
                                    <div className="widget footer-widget">
                                        <h3 className="widget-title">Partners</h3>

                                        <ul className="footer-menu">
                                            <li><Link to="/Partners/Travel-Agencies">Travel Agencies</Link></li>
                                            <li><Link to="/Partners/Airlines">Airlines</Link></li>
                                            <li><Link to="/Partners/Hotels">Hotels & Concierges</Link></li>
                                            <li><Link to="/Partners/Travel-bloggers-affiliate-partners">Travel Bloggers & Affiliates</Link></li>
                                            <li><Link to="/Partners/DMO">DMO's</Link></li>
                                            <li><Link to="/Partners/DMC">DMC's</Link></li>
                                            <li><Link to="/Partners/OTA">OTA's & Marketplaces</Link></li>
                                            <li><Link to="/Partners/Cruises">Cruises</Link></li>
                                            <li><Link to="/Partners/Car-Rental">Car Rentals</Link></li>
                                            <li><Link to="/Partners/Visitor-Centers">Visitor Centers</Link></li>

                                        </ul>
                                    </div>

                                </div>


                                <div className="col-lg-3 col-md-6">
                                    <div className="widget footer-widget">
                                        <h3 className="widget-title">Solutions</h3>

                                        <ul className="footer-menu">
                                            <li><Link to="/Solutions/Partner-solutions">Partnership</Link></li>
                                            <li><Link to="/Solutions/Distribution">Distribution</Link></li>
                                            <li style={{ height: "30px" }}></li>
                                        </ul>

                                        <h3 className="widget-title">Partners</h3>
                                        <ul className="footer-menu">
                                            <li><a href="https://partners.tourzone.com/Login" rel='noreferrer' target="_blank">Supplier login</a></li>
                                            <li><a href="https://partners.tourzone.com/Login" rel='noreferrer' target="_blank">Travel agents login</a></li>
                                            {/* <li><Link to="/Help">Help</Link></li>*/}
                                        </ul>

                                    </div>

                                </div>


                                <div className="col-lg-3 col-md-6">
                                    <div className="widget footer-widget">
                                        <h3 className="widget-title">Company</h3>
                                        <ul className="footer-menu">
                                            <li><Link to="/Company">About us</Link></li>
                                            <li><Link to="/Contact">Contact</Link></li>
                                            <li><Link to="/Blog">Blog</Link></li>
                                        </ul>
                                        <h3 className="widget-title">Legal</h3>

                                        <ul className="footer-menu">
                                            <li><Link to="/Contact">Terms and Conditions</Link></li>
                                            <li><Link to="/Contact">Privacy Policy</Link></li>
                                            <li><Link to="/Contact">Acceptable User Policy</Link></li>
                                            <li><Link to="/Contact">Terms of Service</Link></li>
                                        </ul>

                                        <ul className="footer-social-link">

                                            <li><a href="https://www.instagram.com/tourzoneofficial" rel='noreferrer' target="_blank"><i className="fab fa-facebook-f"></i></a></li>
                                            <li><a href="https://www.instagram.com/tourzoneofficial" rel='noreferrer' target="_blank"><i className="fab fa-linkedin-in"></i></a></li>

                                            <li><a href="https://www.instagram.com/tourzoneofficial" rel='noreferrer' target="_blank"><i className="fab fa-instagram"></i></a></li>
                                        </ul>
                                    </div>

                                </div>

                            </div>


                        </div>

                        <div className="site-info">
                            <div className="copyright">
                                <p>© 2021 All Rights Reserved Design by <a href="https://www.tourzone.com" rel='noreferrer' target="_blank">Tourzone</a></p>

                            </div>

                            <ul className="site-info-menu">
                                {/*<li>Member of Turkish Travel Agencies Association Company Id :13671 </li>*/}

                            </ul>
                        </div>
                    </div>
                </footer>
                
            </>
        )
    }
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(Footer)