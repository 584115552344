import React, { Component } from 'react'
import { connect } from 'react-redux'
import $ from 'jquery';
import { Link } from 'react-router-dom';
export class Headers extends Component {


	componentDidMount() {



		$(window).on("scroll load", function () {
			if ($(this).scrollTop() >= 30) {
				if ($(".site-header.header_trans-fixed").length) {
					$(".site-header.header_trans-fixed").not(".fixed-dark").addClass("pix-header-fixed");
					$(".fixed-dark").addClass("bg-fixed-dark");
					$(".sticky-logo, .header-button-scroll").show();
					$(".main-logo, .header-button-default").hide()
				}
				if ($(".right-menu.modern").length) {
					$(".right-menu.modern").closest(".fixed-header").addClass("fixed-header-scroll")
				}
			} else {
				if ($(".site-header.header_trans-fixed").length) {
					$(".site-header.header_trans-fixed").not(".fixed-dark").removeClass("pix-header-fixed");
					$(".fixed-dark").removeClass("bg-fixed-dark");
					$(".sticky-logo, .header-button-scroll").hide();
					$(".main-logo, .header-button-default").show()
				}
				if ($(".right-menu.modern").length) {
					$(".right-menu.modern").closest(".fixed-header").removeClass("fixed-header-scroll")
				}
			}
		});







		$(".toggle-menu").on("click", function (e) {
			e.preventDefault();
			var mask = '<div className="mask-overlay">';
			$(mask).hide().appendTo('body').fadeIn('fast');
			$("html").addClass("no-scroll sidebar-open").height(window.innerHeight + "px");
			if ($("#wpadminbar").length) {
				$(".sidebar-open .site-nav").css("top", "46px")
			} else {
				$(".sidebar-open .site-nav").css("top", "0")
			}
		});

		$(".close-menu, .mask-overlay").on("click", function (e) {
			e.preventDefault();
			$("html").removeClass("no-scroll sidebar-open").height("auto");
			$('.mask-overlay').remove();
		});

		var mobW = $(".menu-wrapper").attr("data-top");
		if (window.outerWidth < mobW || $(".site-header").hasClass("topmenu-arrow")) {
			if (!$(".menu-item-has-children i").length) {
				$("header .menu-item-has-children").append('<i class="fa fa-angle-down"></i>');
				$("header .menu-item-has-children i").addClass("hide-drop")
			}
			$("header .menu-item-has-children i").on("click", function () {
				if (!$(this).hasClass("animation")) {
					$(this).parent().toggleClass("is-open");
					$(this).addClass("animation");
					$(this).parent().siblings().removeClass("is-open").find(".fa").removeClass("hide-drop").prev(".sub-menu").slideUp(250);
					if ($(this).hasClass("hide-drop")) {
						if ($(this).closest(".sub-menu").length) {
							$(this).removeClass("hide-drop").prev(".sub-menu").slideToggle(250)
						} else {
							$(".menu-item-has-children i").addClass("hide-drop").next(".sub-menu").hide(250);
							$(this).removeClass("hide-drop").prev(".sub-menu").slideToggle(250)
						}
					} else {
						$(this).addClass("hide-drop").prev(".sub-menu").hide(100).find(".menu-item-has-children a").addClass("hide-drop").prev(".sub-menu").hide(250)
					}
				}
				setTimeout(removeClass, 250);

				function removeClass() {
					$("header .site-main-menu i").removeClass("animation")
				}
			})
		} else {
			$("header .menu-item-has-children i").remove()
		}


		
	}



	closeMenu = () => {
		$("html").removeClass("no-scroll sidebar-open").height("auto");
		$('.mask-overlay').remove();
	}
	render() {
		const pt = window.location.pathname;
		return (
			<header className="site-header header_trans-fixed">
				<div className="container">
					<div className="header-inner">
						<div className="toggle-menu">
							<span className="bar" style={{ background: pt !== "/" && "#333" }}></span>
							<span className="bar" style={{ background: pt !== "/" && "#333" }}></span>
							<span className="bar" style={{ background: pt !== "/" && "#333" }}></span>
						</div>


						<div className="site-mobile-logo">
							<Link to="/" className="logo">
								{this.props.dark === "yes" ?
									<img src="/Assets/img/tzstickylogo.svg" alt="site logo" style={{ height: "16px" }} className="main-logo" />
									:
									<img src="/Assets/img/tz_white.svg" alt="site logo" className="main-logo" />
								}
								<img src="/Assets/img/tzstickylogo.svg" style={{ height: "16px", display:"none" }} alt="site logo" className="sticky-logo" />
							</Link>
						</div>

						<nav className={this.props.dark === "yes" ? "site-nav nav-dark" : "site-nav"}>
							<div className="close-menu">
								<span>Close</span>
								<i className="ei ei-icon_close"></i>
							</div>

							<div className="site-logo">
								<Link to="/" className="logo">
									{this.props.dark === "yes" ?
										<img src="/Assets/img/tz_color.svg" alt="site logo" className="main-logo" />
										:
										<img src="/Assets/img/tz_white.svg" alt="site logo" className="main-logo" />
									}
									<img src="/Assets/img/tzstickylogo.svg" style={{ height: "42px" }} alt="site logo" className="sticky-logo" />
								</Link>
							</div>


							<div className="menu-wrapper" data-top="992">
								<ul className="site-main-menu">
									<li className="site-mobile-logo">
										<Link to="/" onClick={this.closeMenu.bind(this)}>Home</Link>
									</li>
									<li className="menu-item-has-children">
										<Link to="/Industries/" onClick={this.closeMenu.bind(this)}>Industries</Link>
										<ul className="sub-menu">
											<li><Link to="/Industries/Tours-And-Activities" onClick={this.closeMenu.bind(this)}>Tours & Activities</Link></li>
											<li><Link to="/Industries/Trips" onClick={this.closeMenu.bind(this)}>Trips</Link></li>
											<li><Link to="/Industries/Attractions" onClick={this.closeMenu.bind(this)}>Attractions</Link></li>
											<li><Link to="/Industries/Transportation" onClick={this.closeMenu.bind(this)}>Transportation</Link></li>
											<li><Link to="/Industries/Charters" onClick={this.closeMenu.bind(this)}>Charters & Rentals</Link></li>
											<li><Link to="/Industries/Event-and-shows" onClick={this.closeMenu.bind(this)}>Events & Shows</Link></li>
											<li><Link to="/Industries/Workshops-and-classes" onClick={this.closeMenu.bind(this)}>Workshops & Classes</Link></li>
										</ul>

									</li>
									<li className="menu-item-has-children">
										<Link to="/Partners/" onClick={this.closeMenu.bind(this)}>Partners</Link>
										<ul className="sub-menu">
											<li><Link to="/Partners/Travel-Agencies" onClick={this.closeMenu.bind(this)}>Travel Agencies</Link></li>
											<li><Link to="/Partners/Airlines" onClick={this.closeMenu.bind(this)}>Airlines</Link></li>
											<li><Link to="/Partners/Hotels" onClick={this.closeMenu.bind(this)}>Hotels & Concierges</Link></li>
											<li><Link to="/Partners/Travel-bloggers-affiliate-partners" onClick={this.closeMenu.bind(this)}>Travel Bloggers & Affiliates</Link></li>
											<li><Link to="/Partners/DMO" onClick={this.closeMenu.bind(this)}>DMO's</Link></li>
											<li><Link to="/Partners/DMC" onClick={this.closeMenu.bind(this)}>DMC's</Link></li>
											<li><Link to="/Partners/OTA" onClick={this.closeMenu.bind(this)}>OTA's & Marketplaces</Link></li>
											<li><Link to="/Partners/Cruises" onClick={this.closeMenu.bind(this)}>Cruises</Link></li>
											<li><Link to="/Partners/Car-Rental" onClick={this.closeMenu.bind(this)}>Car Rentals</Link></li>
											<li><Link to="/Partners/Visitor-Centers" onClick={this.closeMenu.bind(this)}>Visitor Centers</Link></li>

										</ul>
									</li>
									<li className="menu-item-has-children">
										<Link to="#">Solutions</Link>
										<ul className="sub-menu">
											<li><Link to="/Solutions/Partner-solutions" onClick={this.closeMenu.bind(this)}>Partnership</Link></li>
											<li><Link to="/Solutions/Distribution" onClick={this.closeMenu.bind(this)}>Distribution</Link></li>
										</ul>

									</li>
									<li>
										<Link to="/Company" onClick={this.closeMenu.bind(this)}>Company</Link>
									</li>
								</ul>

								<div className="nav-right">
									<Link to="/Request-a-demo" onClick={this.closeMenu.bind(this)} className="nav-btn2">Request a demo</Link>
									<a href="https://partners.tourzone.com/Login" target="_blank" style={{ marginLeft: "15px" }} className="nav-btn" rel='noreferrer'>Login</a>
								</div>

								<div className="nav-right" >

								</div>
							</div>


						</nav>
					</div>
				</div>

			</header>
		)
	}
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(Headers)