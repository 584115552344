import React, { Component } from 'react'
import { connect } from 'react-redux'

export class ComparasionChartSolutions extends Component {
    render() {
        return (
            <>


                <section className="call-to-action mt-5">

                    <div className="container">
                        <div className="action-content text-center wow pixFadeUp">
                            <h2 className="title">
                                Make Collaborations Effortless<br></br>
                                with Seamless Integration Options
                            </h2>

                            <p>
                               


                            </p>

                            <a href="https://partners.tourzone.com" rel="noreferrer" className="pix-btn btn-light">Get Started now</a>
                        </div>

                    </div>




                </section>
            </>
        )
    }
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(ComparasionChartSolutions)